<template>
    <div v-masonry="masonry"
            id="masonry"
            class="masonry masonry-container" 
            fit-width="true" 
            horizontal-order="true"
            stagger="0.05s" 
            transition-duration="0.5s" 
            item-selector=".masonry-item" 
            gutter="30">
        
        <div v-masonry-tile class="masonry-item mb-3" v-for="tile in tiles" :key="tile">
          <img v-bind:src="tile" @load="onImgLoaded(tile)">
        </div>
      </div>
</template>

<script>
export default {
  name: 'MasonryGrid',
  props: [
      'tiles'
      ],
  created() {
      this.tiles = this.shuffle(this.tiles);
  },
  methods: {
    onImgLoaded(loadedImg) {
      let _self = this;

      _self.tiles.forEach((img, index) => {
        if (loadedImg === img && (index + 1 === _self.tiles.length)) {
          setTimeout(() => {
            // redraw after initial drawing to prevent image overlapping
            _self.$redrawVueMasonry();
          }, 1000);
        }
      });
    },
    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        
        return a;
    }
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
