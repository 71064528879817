<template>
  <div class="row">
    <!-- Welcome -->
    <div class="col-sm-12 mb-5">
      <h1 class="font-secondary text-center">Kontakt & Anfahrt</h1>
      <content-separator></content-separator>
      <h2 class="text-center mb-5">
        Zauberhaft <br/>
        Braut- und Festmoden
      </h2>
      <h3 class="text-center">
          <navbar-contact/>

          <br/>

          Tel: 0152 / 59 75 25 04<br/>
          E-Mail: marina.graf@mode-zauberhaft.de<br/>
      </h3>      

      <h3 class="text-center mt-5">
        <a href="https://www.google.de/maps/place/Zauberhaft+-+Braut+%26+Abendmode/@49.2618035,11.9279313,17z/data=!3m1!4b1!4m5!3m4!1s0x479f8f9aedd1914b:0x3b28d842cefe86c6!8m2!3d49.2618175!4d11.9301041?hl=de" target="_blank" class="btn btn-primary btn-lg">
          <b-icon icon="map" animation="pulse"></b-icon> Bring mich hin !
        </a>
      </h3>
    </div>
  </div>
</template>

<script>

import ContentSeparator from '../components/content/ContentSeparator.vue';
import NavbarContact from '../components/content/NavbarContact.vue';

import brautkleider from '@/assets/images/brautkleider.png';

export default {
  name: 'Kontakt',
  components: {
    ContentSeparator,
    NavbarContact
  },
  data() {
    return {
      headline: 'Kontakt',
      backgroundImages: [
        brautkleider
      ]
    }
  },
  created() {
    
  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
