<template>
  <div class="row">
    <!-- Welcome -->
    <div class="col-sm-12 mb-5">
      <h1 class="font-secondary text-center">Die gewünschte Seite konnte nicht gefunden werden.</h1>
      <content-separator></content-separator>
      
      <p class="text-center">
          Gehen Sie zurück zur <a href="/">Startseite</a> oder öffnen eine andere Seite.
      </p>      
    </div>
  </div>
</template>

<script>

import ContentSeparator from '../components/content/ContentSeparator.vue';

import brautkleider from '@/assets/images/brautkleider.png';

export default {
  name: 'Error',
  components: {
    ContentSeparator
  },
  data() {
    return {
      headline: 'Seite nicht gefunden',
      backgroundImages: [
        brautkleider
      ]
    }
  },
  created() {
    
  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
