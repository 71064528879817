<template>
    <div id="content-header">
        <div class="heading--background">
            <img alt="Landgasthof Winkerling" src="../../assets/logo.png">
        </div>

        <div class="heading--placeholder">
            <transition name="fade">
                <h1 v-show="showHeadline">{{ activeHeadline }}</h1>
            </transition>
        </div>

        <span class="page--progress" :class=currentRouteIndexClass></span>
  </div>
</template>
<script>
export default {
    data() {
        return {
            showHeadline: false,
            currentHeadline: null,
            headline: 'Default',
            routeIndex: 0
        }
    },
    watch: {
        $route(to) {
            var _self = this;

            this.$router.options.routes.forEach(function (routeOption, optionIndex) {
                if (routeOption.path === to.path) {
                    _self.routeIndex = optionIndex;
                }
            });
        }
    },
    created() {
        const _self = this;

        this.routeIndex = 0;
        this.$parent.$on('onPageMounted', function(page) {
            _self.showHeadline = false;
            setTimeout(() => {
                _self.headline = page.headline;
                _self.showHeadline = true;
            }, 500);
        });

        // 
        this.$router.options.routes.forEach(function (routeOption, optionIndex) {            
            if (routeOption.path === _self.$router.currentRoute.path) {
                _self.routeIndex = optionIndex;
            }
        });
    },
    computed: {
        activeHeadline() {
            return this.headline;
        },
        currentRouteIndexClass() {
            return 'progress--' + this.routeIndex;
        }
    }
};
</script>
<style>
</style>
