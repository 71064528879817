import Vue from 'vue'

import Home from '../pages/Home.vue'

import Brautkleider from '../pages/Brautkleider.vue'
import Abendkleider from '../pages/Abendkleider.vue'
import Herrenanzuege from '../pages/Herrenanzuege.vue'
import Kinderkleider from '../pages/Kinderkleider.vue'

import Partnerfirmen from '../pages/Partnerfirmen.vue'

import Kontakt from '../pages/Kontakt.vue'
import Impressum from '../pages/Impressum.vue'

import Error from '../pages/Error.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  linkExactActiveClass: 'active',
  routes: [
    { path: '/', component: Home, name: 'Willkommen' },
    
    { path: '/brautmode', component: Brautkleider, name: 'Brautkleider' },
    { path: '/abendmode', component: Abendkleider, name: 'Abendkleider' },
    { path: '/herrenmode', component: Herrenanzuege, name: 'Herrenanzüge' },
    { path: '/kindermode', component: Kinderkleider, name: 'Kinderkleider' },
    
    { path: '/partnerfirmen', component: Partnerfirmen, name: 'Partnerfirmen' },

    { path: '/kontakt', component: Kontakt, name: 'Kontakt' },
    { path: '/impressum', component: Impressum, name: 'Impressum' },

    { path: '*', component: Error, name: 'Fehler' }
  ]
})

export default router