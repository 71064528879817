<template>
  <div id="app">
    <!-- agile slider -->
    <agile class="background-image" v-bind:class="{ 'fade-content': fadeContent }"
      v-if="backgroundImages.length > 0"
      :slidesToShow=1
      :navButtons="false"
      :dots="false"
      :autoplay="true"
      :autoplaySpeed="10000"
      :fade="true"
      :speed="2000">
        
      <div v-for="backgroundImageSrc in backgroundImages" :key="backgroundImageSrc" class="slide" 
        v-bind:style="{ backgroundImage: 'url(' + backgroundImageSrc + ')' }">
      </div>
    </agile>

    <!-- <img class="background-image" src="@/assets/images/brautstudio-1.png"> -->

    <Header/>

    <!-- TODO slider -->

    <div id="page-content" v-bind:class="{ 'fade-content': fadeContent }">
      <b-container>
        <transition :name="transitionName">
          <router-view></router-view>
        </transition>
      </b-container>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import {} from './components/transitions/HeadlineTransition';

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  data() {
    return {
        transitionName: 'fade',
        pages: [],
        backgroundImages: [],
        agileComponent: null,
        fadeContent: false
    };
  },
  mounted() {
    let me = this;
    let currentRouteName = this.$route.name;

    // listen to router events
    this.$router.options.routes.forEach(function (routeOption) {
      if (routeOption.name === currentRouteName) {
        me.backgroundImages = routeOption.component.data().backgroundImages;
        
        setTimeout(function () {
          me.$children.forEach(function (vueComponent) {
            if (vueComponent.$el.classList.contains('agile')) {
              me.agileComponent = vueComponent;
            }
          });
        });
      }
    });

    // listen to collapse events
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'nav-collapse') {
        me.fadeContent = isJustShown;
      }
    });
  },
  watch: {
    $route (to) {
      let me = this;

      this.$router.options.routes.forEach(function (routeOption) {
        if (routeOption.path === to.path) {
          

          let firstNewImage = null;
          let newBackgroundImages = routeOption.component.data().backgroundImages;
          newBackgroundImages.forEach(function (newBackgroundImage) {
            if (firstNewImage === null) {
              firstNewImage = newBackgroundImage;
            }

            let currentImageExists = false;
            me.backgroundImages.forEach(function(currentImage) {
              if (newBackgroundImage === currentImage) {
                currentImageExists = true;
              }
            });
            
            if (!currentImageExists) {
              me.backgroundImages.push(newBackgroundImage);
            }
          });

          if (newBackgroundImages.length > 0) {
            setTimeout(function () {
              me.agileComponent.reload();

              setTimeout(function () {
                me.backgroundImages.forEach(function(backgroundImage, index) {
                  if (backgroundImage === firstNewImage) {                  
                    // console.log('GO TO NEW INDEX');
                    me.agileComponent.disableAutoPlay();
                    me.agileComponent.goTo(index);

                    setTimeout(function () {
                      // console.log('UPDATE BACKGROUND IMAGES');
                      for (let i = 0; i < index; i++) {
                        // delete me.backgroundImages[i];
                      }
                      // console.log(me.backgroundImages);
                      me.backgroundImages = newBackgroundImages;

                      setTimeout(function () {
                        // console.log('FINAL RELOAD', me.agileComponent);
                        // me.backgroundImages = newBackgroundImages;
                        // me.agileComponent.reload();
                        me.backgroundImages = newBackgroundImages;
                        me.agileComponent.prepareSlides();
                        me.agileComponent.reload();
                        
                        me.agileComponent.goTo(0);
                        me.agileComponent.restartAutoPlay();
                        // me.agileComponent.prepareSlides();
                      }, 0);
                    }, 1000);
                  }
                });
              }, 0);
            }, 0);
          }
        }
      });
      
      let uncollapsedToggler = document.getElementsByClassName('not-collapsed');
      if (uncollapsedToggler.length > 0) {
        uncollapsedToggler[0].click();
      }
    }
  },
  computed: {
    currentRoute() {
      return this.$route;
    },
    currentPageTitle() {
      return this.pageTitle;
    }
  }
}
</script>

<style>
#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  image-rendering: -webkit-optimize-contrast;
  text-align: center;
}

#page-content {
  transition: opacity 500ms ease-out;
}

#page-content.fade-content {
  opacity: 0.1;
}

.background-image {
  transition: filter 500ms linear;
}

.background-image.fade-content {
  filter: brightness(0.2);
  overflow: hidden;
}
</style>
