<template>
    <hr class="content-separator mb-5 mt-4"/>
</template>

<script>
export default {
  name: 'ContentSeparator',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-separator {
  background-image: url('~@/assets/zauberhaft-schmetterling.png');
}
</style>
