<template>
  <div class="single-page-content text-left">
    <b-row class="mb-3">
      <b-col>
        <h1 class="mt-2 text-center">
          Unsere Partner
        </h1>

        <content-separator></content-separator>

        <div class="text-center">
            <p>
                <a href="https://www.michls-landgasthof.de/" target="_blank">
                  Michls Landgasthof - Partyservice & Catering
                </a>
            </p>
            <p>
                <a href="https://www.facebook.com/people/Die-Blumenfee-Floristik-mehr/100063698675046/" target="_blank">
                  <b-icon-facebook></b-icon-facebook>&nbsp;
                  Blumenfee Teublitz
                </a>
            </p>
            <p>
                <a href="https://www.instagram.com/andreasrenner_photography/" target="_blank">
                  <b-icon-instagram></b-icon-instagram>
                  Andreas Renner Fotografie (<strong>@andreasrenner_photography</strong>)
                </a>
            </p>
            <p>
                <a href="https://www.instagram.com/lisa.mariefotografie/" target="_blank">
                  <b-icon-instagram></b-icon-instagram>
                  Lisa Marie Fotografie (<strong>@lisa.mariefotografie</strong>)
                </a>
            </p>
            <p>
                <a href="https://www.instagram.com/jennifer.bruckner.fotografie/" target="_blank">
                  <b-icon-instagram></b-icon-instagram>
                  Jennifer Bruckner Fotografie (<strong>@jennifer.bruckner.fotografie</strong>)
                </a>
            </p>
            <p>
                <a href="https://www.instagram.com/gabi.kirschner/" target="_blank">
                  <b-icon-instagram></b-icon-instagram>
                  Hochzeits Event Planung Gabi Kirschner (<strong>@gabi.kirschner</strong>)
                </a> 
            </p>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>

      </b-col>
    </b-row>
  </div>
</template>

<script>

import ContentSeparator from '../components/content/ContentSeparator.vue';


export default {
  components: {
    ContentSeparator
  },
  name: 'Partnerfirmen',
  data() {
    return {
      headline: 'Wir sind Partner',
      backgroundImages: [
        
      ]
    }
  },
  created() {
    
  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
