<template>
  <header>
    <b-navbar toggleable="lg" type="dark">

      <b-navbar-brand href="/">
        <img class="navbar-brand-image" alt="Zauberhaft - Braut und Festmoden" src="../../assets/zauberhaft-braut-logo.png">
        <h2 class="navbar-brand-title mb-0">Zauberhaft</h2>
        <span class="navbar-brand-subtitle">Braut&dash;  und Festmoden</span>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <img src="../../assets/zauberhaft-schmetterling.png">
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" class="navbar-collapse">
        <b-navbar toggleable="lg" type="dark">
            <!-- Menu -->
            <b-navbar-nav class="navbar-primary">
                <b-nav-item v-for="route in routes" :key="route.path" :to="route.path" v-bind:class="{ 'active' : currentRoute.path === route.path , 'd-none' : route.name === 'Fehler' }">
                  {{route.name}}
                </b-nav-item>
            </b-navbar-nav>

            <!-- Contact -->
            <navbar-contact></navbar-contact>

            <!-- Copyright -->
            <b-navbar-nav class="navbar-secondary">
                <b-nav-text>&#169;{{ new Date().getFullYear() }} Mode Zauberhaft</b-nav-text>
            </b-navbar-nav>
        </b-navbar>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import NavbarContact from '../content/NavbarContact.vue';
export default {
  components: { NavbarContact },
  name: 'Header',
  data() {
    return {
      routes: this.$router.options.routes,
      expanded: false
    };
  },
  computed: {
    currentRoute() {
      return this.$route;
    },
    currentExpanded() {
      return this.expanded;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
