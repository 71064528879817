<template>
  <div class="row text-center">
    <!-- Welcome -->
    <div class="col-sm-12 mb-5">
      <h1 class="font-secondary">
          Zauberhafte Momente erleben
      </h1>
      <content-separator></content-separator>
      
      <p>
        Vielleicht sind wir ein Geheimtipp, weil wir traumhafte und exklusive Brautkleider<br/> 
        und Abendkleider für jeden Geldbeutel haben.
      </p>

      <p>
        Wir vereinbaren nur Einzeltermine in unserem Studio.<br/> 
        So können wir uns ganz um Dich und Deine Begleitungen kümmern.
      </p>

      <!-- <p>
        Bitte beachtet immer die aktuell geltenden Hygiene- & Abstandsregeln.
      </p> -->
    </div>

    <!-- Über mich -->
    <div class="col-sm-12 mt-5 mb-5">
      <h2 class="font-secondary text-center">Alles beginnt mit einem Traum</h2>
      <content-separator></content-separator>
      <p>In der Maßschneiderei meiner Mutter bin ich maßgeblich mit Brautkleidern aufgewachsen.</p>

      <p>Im November 2016 habe ich mir dann meinen Traum vom eigenen Brautmodengeschäft verwirklicht.</p>

      <div class="row">
        <div class="col-sm-12 mt-5 mb-3">
          <img class="rounded-circle" src="@/assets/images/marina-graf.png">
        </div>
        
        <div class="col-sm-12">
          <i>Deine Zauberfee aus Leidenschaft</i> <br/>
          <strong>Marina Graf</strong>
        </div>
      </div>
    </div>

    <!-- Eigene Schneiderei -->
    <div class="col-sm-12 mt-5">
      <h2 class="font-secondary text-center">Eigene Schneiderei</h2>
      <content-separator></content-separator>
      
      <p>
        Als ausgebildete und erfahrene Maßschneiderin sorge ich persönlich
        für perfekte und professionelle Anpassung Deines Traumkleides.
      </p>
      
      <p>
        Persönliche Veränderungen verwandeln Dein Kleid zu einem ganz einzigartigen Glanzstück!
      </p>
    </div>
  </div>
</template>

<script>

import ContentSeparator from '../components/content/ContentSeparator.vue';
import brautkleider from '@/assets/images/brautkleider.png';

export default {
  name: 'Willkommen',
  components: {
    ContentSeparator
  },
  data() {
    return {
      headline: 'Herzlich Willkommen bei Mode Zauberhaft',
      backgroundImages: [
        brautkleider
      ]
    }
  },
  created() {

  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
