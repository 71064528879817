<template>
  <div class="row">
    <!-- Welcome -->
    <div class="col-sm-12 mb-5">
      <h1 class="font-secondary text-center">Kinderkleider</h1>
      <content-separator></content-separator>

      <h3 class="text-center mb-5">
        <span class="badge badge-light p-2 m-2">
          Taufkleider
        </span>

        <span class="badge badge-light p-2 m-2">
          Blumenmädchen
        </span>

        <span class="badge badge-light p-2 m-2">
          Kommunionkleider
        </span>
      </h3>
      
      <masonry-grid v-bind:tiles="tiles"></masonry-grid>
    </div>
  </div>
</template>

<script>

import ContentSeparator from '../components/content/ContentSeparator.vue';
import MasonryGrid from '../components/content/MasonryGrid.vue';

import brautkleider from '@/assets/images/brautkleider.png';

export default {
  name: 'Kinderkleider',
  components: {
    ContentSeparator,
    MasonryGrid
  },
  data() {
    return {
      headline: 'Kinderkleider',
      basePath: '/images/kinderkleider/',
      backgroundImages: [
        brautkleider
      ],
      tiles: []
    }
  },
  created() {
    var ComponentImagesContext = require.context('../../public/images/kinderkleider/', true, /\.(png|jpe?g)$/i, 'lazy');

    var dynamicTiles = [];
    ComponentImagesContext.keys().forEach((imageFilePath) => {
      dynamicTiles.push(imageFilePath.replace('./', this.basePath));
    });

    this.tiles = dynamicTiles;
  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
