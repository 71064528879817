<template>
  <footer>
    <b-navbar fixed="bottom" type="dark">
      <navbar-contact></navbar-contact>
    
      <b-navbar-nav class="navbar-secondary">
          <b-nav-text>&#169;{{ new Date().getFullYear() }} Mode Zauberhaft</b-nav-text>
      </b-navbar-nav>
    </b-navbar>
  </footer>
</template>

<script>

import NavbarContact from '../content/NavbarContact.vue';

export default {
  components: { NavbarContact },
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
